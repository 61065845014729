import React from "react";
import { Link } from "react-router-dom";
import productsData from "../../../assets/data/productsData.json";

function MainWid() {
  const importImage = (imageName) => {
    try {
      return require(`../../../assets/img/project/${imageName}`);
    } catch (error) {
      return require("../../../assets/img/project/h3_project_img01.jpg");
    }
  };

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          {productsData.map((product, index) => (
            <div key={index} className="col-lg-4 mb-4">
              <div className="services-widget">
                <h4 className="widget-title">{product.title}</h4>
                <div className="our-services-list">
                  <img
                    src={importImage(product.image || "default.jpg")}
                    alt={product.subTitle}
                  />
                  <ul className="list-wrap">
                    {product.products.map((subProduct, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={`/products/${product.detailLink}/${subProduct.productLink}`}
                        >
                          {subProduct.subTitle}
                          <i className="fas fa-arrow-right"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MainWid;
