import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import servicesDataCS from "../../../assets/data/translation/cs/servicesData.json";
import servicesDataEN from "../../../assets/data/translation/en/servicesData.json";

function MainWid({ serviceLink }) {
  const { t, i18n } = useTranslation("services");
  const [serviceDetails, setServiceDetails] = useState(null);

  // Always use Czech links, merge them with translated data
  const mergedServicesData = servicesDataCS.map((service, index) => {
    const translatedService =
      i18n.language === "cs" ? service : servicesDataEN[index];
    return {
      ...translatedService,
      links: service.links,
    };
  });

  useEffect(() => {
    const service = mergedServicesData.find((s) => s.links === serviceLink);
    setServiceDetails(service);
  }, [serviceLink, mergedServicesData]);

  if (!serviceDetails) {
    return <div>{t("loadingServiceDetails")}</div>;
  }

  const getImagePath = (imagePath) =>
    `../../../assets/img/services/${imagePath}`;

  return (
    <section className="services-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require(`../../../assets/img/services/${serviceDetails.image}`)}
                  alt={serviceDetails.title}
                />
              </div>
              <div className="services-details-content">
                <h2 className="title">{serviceDetails.title}</h2>
                <p>{serviceDetails.description}</p>
                {serviceDetails.detailedDescription && (
                  <p>{serviceDetails.detailedDescription}</p>
                )}
                {serviceDetails.processSteps && (
                  <div className="services-process-wrap">
                    <h3>{t("serviceInformation")}</h3>
                    {serviceDetails.processSteps.map((step, index) => (
                      <div key={index}>
                        <h4>{step.title}</h4>
                        <p>{step.description}</p>
                        <img src={getImagePath(step.image)} alt={step.title} />
                      </div>
                    ))}
                  </div>
                )}
                {serviceDetails.benefits && (
                  <div className="service-benefits-wrap">
                    <h3>{t("benefitsAndProcessing")}</h3>
                    <ul>
                      {serviceDetails.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <aside className="services-sidebar sticky">
              <div className="services-widget">
                <h4 className="widget-title">{t("allServices")}</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    {mergedServicesData.map((service, index) => (
                      <li key={index}>
                        <a href={`/${i18n.language}/services/${service.links}`}>
                          {service.title}
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
