import React from "react";
import { useTranslation } from "react-i18next";
import servicesDataCS from "../../assets/data/translation/cs/servicesData.json";
import servicesDataEN from "../../assets/data/translation/en/servicesData.json";

function ServiceArea({ className }) {
  const { t, i18n } = useTranslation("servicesTrans");

  // Always use Czech links, merge them with translated data
  const servicesData = servicesDataCS.map((service, index) => {
    const translatedService =
      i18n.language === "cs" ? service : servicesDataEN[index];
    return {
      ...translatedService,
      links: service.links,
    };
  });

  const getImage = (imagePath) => {
    try {
      return require(`../../assets/img/services/${imagePath}`);
    } catch (err) {
      console.error(`Could not load image: ${imagePath}`);
      return undefined;
    }
  };

  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">
                {t("subTitle")}
              </span>
              <h2 className="title tg-element-title">{t("title")}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {servicesData.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="services-item"
                data-wow-delay={service.delay}
                style={{
                  backgroundImage: `url(${getImage(service.image)})`,
                }}
              >
                <div className="services-overlay-content">
                  <h2 className="title">{service.title}</h2>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
