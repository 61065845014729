import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import productsDataCS from "../../../assets/data/translation/cs/productsData.json";
import productsDataEN from "../../../assets/data/translation/en/productsData.json";
import servicesDataCS from "../../../assets/data/translation/cs/servicesData.json";
import servicesDataEN from "../../../assets/data/translation/en/servicesData.json";

function FooterOne() {
  const { t, i18n } = useTranslation("footer");
  const today = new Date();
  const location = useLocation();
  const navigate = useNavigate();

  const productsData = i18n.language === "cs" ? productsDataCS : productsDataEN;
  const servicesData = i18n.language === "cs" ? servicesDataCS : servicesDataEN;

  const changeLanguage = (lng) => {
    const currentPath = location.pathname.split("/").slice(2).join("/");
    navigate(`/${lng}/${currentPath}`);
    i18n.changeLanguage(lng);
  };

  const handleNavigation = (path) => {
    navigate(`/${i18n.language}${path}`);
  };

  return (
    <footer>
      <div
        className="footer-area footer-bg"
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/footer_bg.jpg")})`,
        }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h2 className="fw-title">{t("aboutUsTitle")}</h2>
                  <div className="footer-content">
                    <p>{t("aboutUsDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">{t("ourProductsTitle")}</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      {productsData.map((product, index) => (
                        <li key={index}>
                          <a
                            href=""
                            onClick={() => handleNavigation(`/products/`)}
                          >
                            <i className="fas fa-angle-double-right"></i>
                            {product.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">{t("ourServicesTitle")}</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      {servicesData.map((service, index) => (
                        <li key={index}>
                          <a
                            href=""
                            onClick={() => handleNavigation(`/services/`)}
                          >
                            <i className="fas fa-angle-double-right"></i>
                            {service.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">{t("language")}</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <button onClick={() => changeLanguage("cs")}>
                          <img
                            src={require("../../../assets/img/icon/flags/cs.png")}
                            alt="Čeština"
                            style={{ width: "20px", marginRight: "8px" }}
                          />
                          Čeština
                        </button>
                      </li>
                      <li>
                        <button onClick={() => changeLanguage("en")}>
                          <img
                            src={require("../../../assets/img/icon/flags/en.png")}
                            alt="English"
                            style={{ width: "20px", marginRight: "8px" }}
                          />
                          English
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-12">
                  <div className="logo">
                    <a href={`/${i18n.language}`}>
                      <img
                        src={require(`../../../assets/img/logo/logo.png`)}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="footer-contact">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <span>{t("phoneNumberLabel")}</span>
                      <a href="tel:0123456789">+(420) 266 610 930</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text">
                  <p>© {today.getFullYear()} MARC spol s r.o.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
