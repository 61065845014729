import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderOne from "../../Layouts/Headers/HeaderOne";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid"; // Make sure MainWid is adjusted to accept and use the serviceDetails prop
import BrandAreaWid from "../../Common/BrandAreaWid";
import { useTranslation } from "react-i18next";

function ServiceDetails() {
  const { serviceLink } = useParams(); // Extract the "serviceLink" from the URL
  const { t, i18n } = useTranslation("servicesTrans");
  const currentLanguage = i18n.language;
  const [serviceDetails, setServiceDetails] = useState(null);

  useEffect(() => {
    console.log("Current serviceLink: ", serviceLink);
    const service = servicesData.find((s) => s.links === serviceLink);
    setServiceDetails(service);
  }, [serviceLink]);

  if (!serviceDetails) {
    return <div>{t("loadingServiceDetails")}</div>; // or any other loading indicator
  }

  return (
    <>
      <HeaderOne />
      <main>
        <InnerPageTitle
          title={serviceDetails.title}
          paths={[
            { name: t("home"), path: `/${currentLanguage}/` },
            { name: t("services"), path: `/${currentLanguage}/services` },
            {
              name: serviceDetails.title,
              path: `/${currentLanguage}/services/${serviceLink}`,
            },
          ]}
        />
        <MainWid key={serviceLink} serviceLink={serviceLink} />
        <BrandAreaWid className="pt-0" />
      </main>
      <FooterOne />
    </>
  );
}

export default ServiceDetails;
