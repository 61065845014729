import React from "react";
import isoImg1 from "../../assets/img/iso/iso_cert_01.jpg";
import isoImg2 from "../../assets/img/iso/iso_cert_02.jpg";
function MainWid() {
  return (
    <section className="pt-50">
      <div className="container">
        <div className="row justify-content-center pt-50 pb-50">
          <div className="col-xl-6 col-lg-10 ">
            <img src={isoImg1} alt="ISO CERTIFIKÁT MARCSTEEL 1" />
          </div>
          <div className="col-xl-6 col-lg-10">
            <img src={isoImg2} alt="ISO CERTIFIKÁT MARCSTEEL 2" />
          </div>
        </div>
        <div className="row justify-content-center"></div>
      </div>
    </section>
  );
}

export default MainWid;
