import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import productDataCS from "../../../../assets/data/translation/cs/productsData.json";
import productDataEN from "../../../../assets/data/translation/en/productsData.json";
import HeaderOne from "../../../Layouts/Headers/HeaderOne";
import InnerPageTitle from "../../../Helpers/InnerPageTitle";
import FooterOne from "../../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";
import BrandAreaWid from "../../../Common/BrandAreaWid";

function SubProductDetails() {
  const { productLink, subProductLink } = useParams(); // Extract both parameters from the URL
  const { t, i18n } = useTranslation("products");
  const [productDetails, setProductDetails] = useState(null);
  const [subProductDetails, setSubProductDetails] = useState(null);
  const currentLanguage = i18n.language;

  // Select product data based on current language
  const productData = currentLanguage === "cs" ? productDataCS : productDataEN;

  useEffect(() => {
    console.log(
      "Current productLink: ",
      productLink,
      " and subProductLink: ",
      subProductLink
    );
    const product = productData.find((p) => p.detailLink === productLink);
    if (!product) {
      console.error("Product not found for link: ", productLink);
      return;
    }
    const subProduct = product.products.find(
      (sp) => sp.productLink === subProductLink
    );
    if (!subProduct) {
      console.error("Sub-product not found for link: ", subProductLink);
      return;
    }
    setProductDetails(product);
    setSubProductDetails(subProduct);
  }, [productLink, subProductLink, productData]);

  if (!productDetails || !subProductDetails) {
    return (
      <div>
        {t("loadingDetails")} {t("ifThisPersists")}
      </div>
    );
  }

  return (
    <>
      <HeaderOne />
      <main>
        <InnerPageTitle
          title={subProductDetails.subTitle || productDetails.title}
          paths={[
            { name: t("home"), path: `/${currentLanguage}/` },
            { name: t("productRange"), path: `/${currentLanguage}/products` },
            {
              name: subProductDetails.subTitle,
              path: `/${currentLanguage}/products/${productLink}/${subProductLink}`,
            },
          ]}
        />

        <MainWid
          key={subProductLink}
          productDetails={productDetails}
          subProductDetails={subProductDetails}
        />
        <BrandAreaWid className="pt-0" />
      </main>
      <FooterOne />
    </>
  );
}

export default SubProductDetails;
