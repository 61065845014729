import React from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";

function MainWid() {
  const { t } = useTranslation("contact");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID", // replace with your service ID
        "YOUR_TEMPLATE_ID", // replace with your template ID
        e.target,
        "YOUR_USER_ID" // replace with your user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent Successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Message Sending Failed");
        }
      );
  };

  return (
    <section className="contact-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">{t("askUsTitle")}</h2>
              <p>{t("sendUsMessage")}</p>
              <form onSubmit={sendEmail} className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder={t("firstNamePlaceholder")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder={t("lastNamePlaceholder")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t("emailPlaceholder")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder={t("phonePlaceholder")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder={t("subjectPlaceholder")}
                  />
                </div>
                <div className="form-grp">
                  <textarea
                    id="message"
                    name="message"
                    placeholder={t("messagePlaceholder")}
                  ></textarea>
                </div>
                <button className="btn" type="submit">
                  {t("sendButton")}
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">{t("contactUsTitle")}</h2>
              <p>
                {t("headOffice")} <br />
                {t("branchOffice")}
              </p>
              <p>{t("companyDetails")}</p>
              <p dangerouslySetInnerHTML={{ __html: t("bankDetails") }}></p>
              <p>{t("registrationInfo")}</p>
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:0123456789">+(420) 266 610 930</a>
                      {t("fax")}: +420 266 610 927
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:info@marcsteel.cz">info@marcsteel.cz</a>
                      <a href="mailto:tomas.smrcka@marcsteel.cz">
                        tomas.smrcka@marcsteel.cz
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">
                      <a
                        href="https://maps.app.goo.gl/dyJvK18NUVS1cVM38"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>
                          U Elektry 650 <br /> 198 00 Praha
                        </p>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="contact-map">
            <iframe
              title="Google Map"
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1221.8097940120513!2d14.52139377259728!3d50.1014819874534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470becd51caca059%3A0xc66270aa8ed82bae!2sU%20Elektry%2C%20198%2000%20Praha%209%2C%20Czechia!5e0!3m2!1sen!2sus!4v1718817627542!5m2!1sen!2sus"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
