import React from "react";
import HeaderOne from "../Layouts/Headers/HeaderOne";
import Hero from "./Hero";
import ServiceArea from "./ServiceArea";
import AboutArea from "./AboutArea";
import ProductsArea from "./ProductsArea";
import TeamArea from "./TeamArea";
import FooterOne from "../Layouts/Footers/FooterOne";

function HomeOne() {
  return (
    <>
      <HeaderOne />
      <main>
        <Hero />
        <AboutArea />
        <ProductsArea />
        <ServiceArea className="services-area" />
      </main>
      <FooterOne />
    </>
  );
}

export default HomeOne;
