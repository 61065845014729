import React from "react";
import FooterOne from "../Layouts/Footers/FooterOne";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import AboutArea from "../HomeOne/AboutArea";
import WorkArea from "./WorkArea";
import HistoryArea from "./HistoryArea";
import TeamArea from "../HomeOne/TeamArea";
import TestimonialArea from "./TestimonialArea";
import BrandAreaWid from "../Common/BrandAreaWid";
import HeaderOne from "../Layouts/Headers/HeaderOne";
import { useTranslation } from "react-i18next";

function Index() {
  const { t, i18n } = useTranslation("about");
  const currentLanguage = i18n.language;

  return (
    <>
      <HeaderOne />
      <InnerPageTitle
        title={t("aboutCompany")}
        paths={[
          { name: t("home"), path: `/${currentLanguage}/` },
          { name: t("aboutCompany"), path: `/${currentLanguage}/about` },
        ]}
      />
      <AboutArea className="pt-120" />
      <HistoryArea />
      <div
        className="area-bg-five"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/area_bg05.jpg")})`,
        }}
      >
        <TeamArea className="pt-0" />
      </div>
      <BrandAreaWid />
      <FooterOne />
    </>
  );
}

export default Index;
