import React from "react";
import HeaderOne from "../Layouts/Headers/HeaderOne";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import ServiceArea from "../HomeOne/ServiceArea"; // This might need adjustments for dynamic links
import FooterOne from "../Layouts/Footers/FooterOne";
import { useTranslation } from "react-i18next";

function Services() {
  const { t, i18n } = useTranslation("servicesTrans");
  const currentLanguage = i18n.language;

  return (
    <>
      <HeaderOne />
      <main>
        <InnerPageTitle
          title={t("ourServices")}
          paths={[
            { name: t("home"), path: `/${currentLanguage}/` },
            { name: t("services"), path: `/${currentLanguage}/services` },
          ]}
        />
        {/* The ServiceArea component should be adapted to use dynamic links if it's not already doing so */}
        <ServiceArea className="inner-services-area pt-115 pb-90" />
      </main>
      <FooterOne />
    </>
  );
}

export default Services;
