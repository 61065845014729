import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TeamMember from "./TeamMember";
import csTeamData from "../../assets/data/translation/cs/teamData.json";
import enTeamData from "../../assets/data/translation/en/teamData.json";

function TeamArea({ className }) {
  const { t, i18n } = useTranslation("team");
  const [teamData, setTeamData] = useState(csTeamData);

  useEffect(() => {
    if (i18n.language === "cs") {
      setTeamData(csTeamData);
    } else if (i18n.language === "en") {
      setTeamData(enTeamData);
    }
  }, [i18n.language]);

  return (
    <section className={`team-area pt-115 pb-90 ${className}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">{t("subTitle")}</span>
              <h2 className="title">{t("title")}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {teamData.map((member) => (
            <TeamMember key={member.id} member={member} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamArea;
