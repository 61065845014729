import React from "react";
import { useTranslation } from "react-i18next";
import productsDataCS from "../../assets/data/translation/cs/productsData.json";
import productsDataEN from "../../assets/data/translation/en/productsData.json";
import { Link } from "react-router-dom";

function MainWid() {
  const { t, i18n } = useTranslation("products");

  // Always use Czech links, merge them with translated data
  const productsData = productsDataCS.map((product, index) => {
    const translatedProduct =
      i18n.language === "cs" ? product : productsDataEN[index];
    return {
      ...translatedProduct,
      detailLink: product.detailLink,
      products: product.products.map((subProduct, subIndex) => ({
        ...translatedProduct.products[subIndex], // Use translated subProduct
        productLink: subProduct.productLink, // Keep the Czech productLink
      })),
    };
  });

  const importImage = (imageName) => {
    try {
      return require(`../../assets/img/project/${imageName}`);
    } catch (error) {
      console.error("Failed to load image:", imageName);
      return require("../../assets/img/project/h3_project_img01.jpg");
    }
  };

  return (
    <section className="inner-project-area pt-115 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">{t("subTitle")}</span>
              <h2 className="title">{t("title")}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {productsData.map((product, index) => (
            <div key={index} className="col-lg-12 mb-4">
              <div className="services-widget">
                <h4 className="widget-title">{product.title}</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    {product.products.map((subProduct, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={`/${i18n.language}/products/${product.detailLink}/${subProduct.productLink}`}
                        >
                          {subProduct.subTitle}
                          <i className="fas fa-arrow-right"></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MainWid;
