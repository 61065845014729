import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import csProductsData from "../../../../assets/data/translation/cs/productsData.json";
import enProductsData from "../../../../assets/data/translation/en/productsData.json";

function MainWid() {
  const { productLink, subProductLink } = useParams();
  const { t, i18n } = useTranslation("products");

  const [subProductDetails, setSubProductDetails] = useState(null);

  const productsData = i18n.language === "cs" ? csProductsData : enProductsData;

  useEffect(() => {
    const product = productsData.find((p) => p.detailLink === productLink);
    if (product) {
      const subProduct = product.products.find(
        (sp) => sp.productLink === subProductLink
      );
      setSubProductDetails(subProduct);
    } else {
      console.error("Product or subproduct not found.");
    }
  }, [productLink, subProductLink, productsData]);

  if (!subProductDetails) {
    return <div>{t("subProductNotFound")}</div>;
  }

  const product = productsData.find((p) => p.detailLink === productLink);

  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="project-details-wrap">
              <div className="project-details-content">
                <h2 className="title">{subProductDetails.subTitle}</h2>

                {subProductDetails.additionalFeatures && (
                  <>
                    <p>{t("additionalInformation")}</p>
                    <ul>
                      {subProductDetails.additionalFeatures.map(
                        (feature, index) => (
                          <li key={index}>{feature}</li>
                        )
                      )}
                    </ul>
                  </>
                )}
                {subProductDetails.boldText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: subProductDetails.boldText,
                    }}
                  ></p>
                )}
                {subProductDetails.sheets &&
                  subProductDetails.sheets.items.length > 0 && (
                    <>
                      <h3 className="title-two pt-50">
                        {subProductDetails.sheets.title}
                      </h3>
                      <table className="table fixed-table">
                        <thead>
                          <tr>
                            <th>{t("product")}</th>
                            <th>{t("thickness")}</th>
                            <th>{t("width")}</th>
                            <th>{t("length")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subProductDetails.sheets.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.product}</td>
                              <td>{item.thickness}</td>
                              <td>{item.width}</td>
                              <td>{item.length || t("na")}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {subProductDetails.sheets.additionalFeatures && (
                        <ul>
                          {subProductDetails.sheets.additionalFeatures.map(
                            (feature, index) => (
                              <li key={index}>{feature}</li>
                            )
                          )}
                        </ul>
                      )}
                    </>
                  )}
                {subProductDetails.cutShapes && (
                  <>
                    <h3 className="title-two pt-50">
                      {subProductDetails.cutShapes.title}
                    </h3>
                    <p>{subProductDetails.cutShapes.description}</p>
                    <p>
                      {t("methods")}: {subProductDetails.cutShapes.methods}
                    </p>
                    <p>
                      {t("thickness")}: {subProductDetails.cutShapes.thickness}
                    </p>
                    <p>
                      {t("maxDiameter")}:{" "}
                      {subProductDetails.cutShapes.maxDiameter}
                    </p>
                  </>
                )}
                {subProductDetails.implementations &&
                  subProductDetails.implementations.length > 0 && (
                    <>
                      {subProductDetails.implementations.map(
                        (implementation, index) => (
                          <div key={index}>
                            <h4 className="title-three">
                              {implementation.title}
                            </h4>
                            {implementation.items &&
                              implementation.items.length > 0 && (
                                <table className="table fixed-table">
                                  <thead>
                                    <tr>
                                      <th>{t("surfaceFinish")}</th>
                                      <th>{t("norm")}</th>
                                      <th>{t("dimension")}</th>
                                      <th>{t("productionLengths")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {implementation.items.map((item, idx) => (
                                      <tr key={idx}>
                                        <td>{item["Provedení / povrch"]}</td>
                                        <td
                                          dangerouslySetInnerHTML={{
                                            __html: item.Norma.replace(
                                              /,/g,
                                              "<br />"
                                            ),
                                          }}
                                        ></td>
                                        <td
                                          dangerouslySetInnerHTML={{
                                            __html: item.Rozměr.replace(
                                              /,/g,
                                              "<br />"
                                            ),
                                          }}
                                        ></td>
                                        <td>{item["Výrobní délky"]}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                          </div>
                        )
                      )}
                    </>
                  )}
                {subProductDetails.surfaceTreatments &&
                  subProductDetails.surfaceTreatments.length > 0 && (
                    <>
                      <h3 className="title-two pt-50">
                        {subProductDetails.surfaceTreatments[0].title}
                      </h3>
                      <table className="table fixed-table">
                        <thead>
                          <tr>
                            <th>{t("standard")}</th>
                            <th>{t("norm")}</th>
                            <th>{t("din")}</th>
                            <th>{t("localNorm")}</th>
                            <th>{t("productionProcess")}</th>
                            <th>{t("appearance")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subProductDetails.surfaceTreatments.map(
                            (treatment, index) => (
                              <tr key={index}>
                                <td>{treatment.standard}</td>
                                <td>{treatment.norm}</td>
                                <td>{treatment.din}</td>
                                <td>{treatment.localNorm}</td>
                                <td>{treatment.productionProcess}</td>
                                <td>{treatment.appearance}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                {subProductDetails.steelGrades &&
                  subProductDetails.steelGrades.length > 0 && (
                    <>
                      <h3 className="title-two pt-50">
                        {t("commonSteelGrades")}
                      </h3>
                      <table className="table fixed-table">
                        <thead>
                          <tr>
                            {subProductDetails.steelGrades.some(
                              (grade) => grade.ČSN
                            ) && <th>ČSN</th>}
                            {subProductDetails.steelGrades.some(
                              (grade) => grade["W.-Nr."]
                            ) && <th>W.-Nr.</th>}
                            {subProductDetails.steelGrades.some(
                              (grade) => grade.AISI
                            ) && <th>AISI</th>}
                            {subProductDetails.steelGrades.some(
                              (grade) => grade.NAME
                            ) && <th>NAME</th>}
                            {subProductDetails.steelGrades.some(
                              (grade) => grade.DIN
                            ) && <th>DIN</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {subProductDetails.steelGrades.map((grade, index) => (
                            <tr key={index}>
                              {grade.ČSN && <td>{grade.ČSN}</td>}
                              {grade["W.-Nr."] && <td>{grade["W.-Nr."]}</td>}
                              {grade.AISI && <td>{grade.AISI}</td>}
                              {grade.NAME && <td>{grade.NAME}</td>}
                              {grade.DIN && <td>{grade.DIN}</td>}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="services-sidebar sticky">
              {product && product.products && product.products.length > 1 && (
                <div className="services-widget">
                  <h4 className="widget-title">{product.title}</h4>
                  <div className="our-services-list">
                    <ul className="list-wrap">
                      {product.products.map((subProduct, index) => (
                        <li key={index}>
                          <a
                            href={`/${i18n.language}/products/${product.detailLink}/${subProduct.productLink}`}
                          >
                            {subProduct.subTitle}
                            <i className="fas fa-arrow-right"></i>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className="services-widget">
                <h4 className="widget-title">{t("productRange")}</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    {productsData.map((product, index) => (
                      <li key={index}>
                        <a
                          href={`/${i18n.language}/products/${product.detailLink}/${product.products[0].productLink}`}
                        >
                          {product.title}
                          <i className="fas fa-arrow-right"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
