import React, { useEffect } from "react";
import Routers from "./Routers";
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-2BZ611S34P");

    // Report page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
