import React from "react";
import { useTranslation } from "react-i18next";
import productsDataCS from "../../assets/data/translation/cs/productsData.json";
import productsDataEN from "../../assets/data/translation/en/productsData.json";

function ProductsArea() {
  const { t, i18n } = useTranslation("products");

  // Always use Czech links, merge them with translated data
  const productsData = productsDataCS.map((product, index) => {
    const translatedProduct =
      i18n.language === "cs" ? product : productsDataEN[index];
    return {
      ...translatedProduct,
      detailLink: product.detailLink,
      products: product.products.map((subProduct, subIndex) => ({
        ...translatedProduct.products[subIndex], // Use translated subProduct
        productLink: subProduct.productLink, // Keep the Czech productLink
      })),
    };
  });

  const imageHandler = () => {
    const els = document.querySelectorAll(".collapse-anim");
    els.forEach((item) => {
      const height = item.scrollHeight;
      item.style.setProperty("--max-height", `${height}px`);
    });
  };

  return (
    <section className="project-area project-bg">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="section-title white-title mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">
                {t("subTitle")}
              </span>
              <h2 className="title tg-element-title">{t("title")}</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="view-all-btn text-end mb-50">
              <a href={`/${i18n.language}/products`} className="btn">
                {t("viewAll")}
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {productsData.map((product) => (
              <div
                key={product.id}
                className="project-item wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="project-thumb collapse-anim">
                  <img
                    src={require(`../../assets/img/project/${product.image}`)}
                    onLoad={imageHandler}
                    alt=""
                  />
                </div>
                <div className="project-content">
                  <div className="left-side-content">
                    <span>{product.category}</span>
                    <h2 className="title pt-50">
                      <a
                        href={`/${i18n.language}/products/${product.detailLink}/${product.products?.[0]?.productLink}`}
                      >
                        {product.title}
                      </a>
                    </h2>
                    <p className="collapse-anim">{product.description}</p>
                  </div>
                  <div className="project-link">
                    <a
                      href={`/${i18n.language}/products/${product.detailLink}/${product.products?.[0]?.productLink}`}
                    >
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductsArea;
