import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SliderCom from "../Helpers/SliderCom"; // Adjust this import path as necessary
import bannerShape from "../../assets/img/banner/banner_shape.jpg";
import bannerBg from "../../assets/img/banner/banner_bg.jpg";
// Imported brand images
import brandImg01 from "../../assets/img/brand/brand_img01.png";
import brandImg02 from "../../assets/img/brand/brand_img02.png";
import brandImg03 from "../../assets/img/brand/brand_img03.png";
import brandImg04 from "../../assets/img/brand/brand_img04.png";
import brandImg05 from "../../assets/img/brand/brand_img05.png";
import brandImg06 from "../../assets/img/brand/brand_img06.png";
import brandImg07 from "../../assets/img/brand/brand_img07.png";
import brandImg08 from "../../assets/img/brand/brand_img08.png";

const brandImages = [
  brandImg01,
  brandImg02,
  brandImg03,
  brandImg04,
  brandImg05,
  brandImg06,
  brandImg07,
  brandImg08,
];

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 2000,
  autoplay: true,
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: { slidesToShow: 4, slidesToScroll: 1, infinite: true },
    },
    {
      breakpoint: 992,
      settings: { slidesToShow: 3, slidesToScroll: 1 },
    },
    {
      breakpoint: 767,
      settings: { slidesToShow: 3, slidesToScroll: 1, arrows: false },
    },
    {
      breakpoint: 575,
      settings: { slidesToShow: 2, slidesToScroll: 1, arrows: false },
    },
  ],
};

function Hero() {
  const [activeTooltip, setActiveTooltip] = useState(null);
  const { t, i18n } = useTranslation("hero");

  const toggleTooltip = (id) => {
    setActiveTooltip(activeTooltip === id ? null : id);
  };

  useEffect(() => {}, []);

  const tooltips = t("tooltips", { returnObjects: true });

  return (
    <section className="banner-area">
      <div
        className="banner-bg"
        style={{ background: `url(${bannerBg})`, backgroundSize: "cover" }}
      >
        <div className="banner-content">
          <h2 className="title wow fadeInDown" data-wow-delay=".2s">
            {t("title")}
          </h2>
          <p className="wow fadeInUp" data-wow-delay=".2s">
            {t("description")}
          </p>
          <a
            href={`/${i18n.language}/products`}
            className="btn wow fadeInUp"
            data-wow-delay=".4s"
          >
            {t("learnMore")}
          </a>
        </div>
        <div className="banner-tooltip-wrap">
          {tooltips.map((tooltip, index) => (
            <div
              key={index}
              className={`tooltip-item ${
                activeTooltip === index ? "active" : ""
              }`}
              onClick={() => toggleTooltip(index)}
            >
              <div className="tooltip-btn pulse">
                <i className="fas fa-plus"></i>
              </div>
              <a href={`/${i18n.language}/products`}>
                <div className="tooltip-content">
                  <h2 className="title">{tooltip.title}</h2>
                  <p>{tooltip.description}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="brand-area">
        <div className="container">
          <div className="brand-inner">
            <SliderCom settings={sliderSettings}>
              {brandImages.map((image, index) => (
                <div key={index} className="brand-item">
                  <img src={image} alt={`Brand ${index + 1}`} />
                </div>
              ))}
            </SliderCom>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
