// TeamMember.js
import React from "react";

function TeamMember({ member }) {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
      <div className="team-item">
        <div className="team-thumb"></div>
        <div className="team-content">
          <h2 className="title">{member.name}</h2>
          <span>{member.position}</span>
          <div className="contact-info">
            <span>T: {member.phone}</span>
            <span>
              mobil: <a href={`tel:${member.mobile}`}>{member.mobile}</a>
            </span>
            <span>
              <a href={`mailto:${member.email}`}>{member.email}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
