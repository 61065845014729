import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function AboutArea({ className }) {
  const { t, i18n } = useTranslation("about");
  const location = useLocation();

  // Get the current language
  const currentLanguage = i18n.language;

  // Helper function to create URLs with the current language
  const createLocalizedURL = (path) => `/${currentLanguage}${path}`;

  return (
    <section className={`about-area pb-120 pt-120 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap">
              <img
                src={require(`../../assets/img/images/about_img01.jpg`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".4s"
              />
              <img
                src={require(`../../assets/img/images/about_img02.jpg`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".2s"
              />
              <div className="about-experiences-wrap">
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">{t("experience_years")}</h6>
                  </div>
                </div>
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">{t("network_description")}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about-content">
              <div className="section-title mb-25 tg-heading-subheading">
                <span className="sub-title tg-element-title">
                  {t("company")}
                </span>
                <h2 className="title tg-element-title">{t("welcome")}</h2>
              </div>
              <p>{t("main_activity")}</p>
              <p>{t("direct_connection")}</p>
              <p>{t("price_delivery")}</p>
              <a href={createLocalizedURL("/about")} className="btn">
                {t("more_about_us")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutArea;
