import React from "react";
import { useTranslation } from "react-i18next";

function HistoryArea({ videoHandler }) {
  const { t } = useTranslation("history");

  return (
    <section className="history-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="history-img-wrap">
              <ul className="list-wrap">
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img01.jpg`)}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img02.jpg`)}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img03.jpg`)}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="history-content">
              <div className="section-title mb-20">
                <span className="sub-title">{t("subTitle")}</span>
                <h2 className="title">{t("title")}</h2>
              </div>

              <div className="history-list">
                <ul className="list-wrap">
                  {t("listItems", { returnObjects: true }).map(
                    (item, index) => (
                      <li key={index}>
                        <i className="fas fa-check"></i>
                        {item}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HistoryArea;
