import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import productData from "../../../assets/data/productsData.json";
import HeaderOne from "../../Layouts/Headers/HeaderOne";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";
import BrandAreaWid from "../../Common/BrandAreaWid";
import { useTranslation } from "react-i18next";

function ProductDetails() {
  const { productLink } = useParams();
  const { t, i18n } = useTranslation("products");
  const [productDetails, setProductDetails] = useState(null);
  const currentLanguage = i18n.language;

  useEffect(() => {
    const product = productData.find((p) => p.detailLink === productLink);
    if (!product) {
      console.error("Product not found for link: ", productLink);
      return;
    }

    let updatedProductDetails = { ...product };

    // If there's only one subproduct, update productDetails with subproduct details
    if (product.products.length === 1) {
      updatedProductDetails = {
        ...updatedProductDetails,
        ...product.products[0],
      };
    }

    setProductDetails(updatedProductDetails);
  }, [productLink]);

  if (!productDetails) {
    return <div>{t("loadingProductDetails")}</div>;
  }

  return (
    <>
      <HeaderOne />
      <main>
        <InnerPageTitle
          title={productDetails.title}
          paths={[
            { name: t("home"), path: `/${currentLanguage}/` },
            { name: t("products"), path: `/${currentLanguage}/products` },
            {
              name: productDetails.title,
              path: `/${currentLanguage}/products/${productLink}`,
            },
          ]}
        />
        <MainWid key={productLink} productDetails={productDetails} />
        <BrandAreaWid className="pt-0" />
      </main>
      <FooterOne />
    </>
  );
}

export default ProductDetails;
