import React from "react";
import SliderCom from "../Helpers/SliderCom";
import brandImg01 from "../../assets/img/brand/brand_img01.png";
import brandImg02 from "../../assets/img/brand/brand_img02.png";
import brandImg03 from "../../assets/img/brand/brand_img03.png";
import brandImg04 from "../../assets/img/brand/brand_img04.png";
import brandImg05 from "../../assets/img/brand/brand_img05.png";
import brandImg06 from "../../assets/img/brand/brand_img06.png";
import brandImg07 from "../../assets/img/brand/brand_img07.png";
import brandImg08 from "../../assets/img/brand/brand_img08.png";

function BrandAreaWid({ className }) {
  const images = [
    { id: 1, src: brandImg01, alt: "Brand 1" },
    { id: 2, src: brandImg02, alt: "Brand 2" },
    { id: 3, src: brandImg03, alt: "Brand 3" },
    { id: 4, src: brandImg04, alt: "Brand 4" },
    { id: 5, src: brandImg05, alt: "Brand 5" },
    { id: 6, src: brandImg06, alt: "Brand 6" },
    { id: 7, src: brandImg07, alt: "Brand 7" },
    { id: 8, src: brandImg08, alt: "Brand 8" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 4, slidesToScroll: 1, infinite: true },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 3, slidesToScroll: 1 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 2, slidesToScroll: 1, arrows: false },
      },
      {
        breakpoint: 575,
        settings: { slidesToShow: 1, slidesToScroll: 1, arrows: false },
      },
    ],
  };

  return (
    <div className={`inner-brand-area pt-120 pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row brand-active-three">
          <SliderCom settings={settings}>
            {images.map((image) => (
              <div className="col-12" key={image.id}>
                <div className="brand-item">
                  <img src={image.src} alt={image.alt} />
                </div>
              </div>
            ))}
          </SliderCom>
        </div>
      </div>
    </div>
  );
}

export default BrandAreaWid;
