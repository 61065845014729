import React from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import HomeOne from "./Components/HomeOne";
import About from "./Components/About";
import Contact from "./Components/Contact";
import TeamDetails from "./Components/TeamDetails";
import Error from "./Components/Error";
import Services from "./Components/Services";
import ServiceDetails from "./Components/Services/ServiceDetails";
import Product from "./Components/Product";
import ProductDetails from "./Components/Product/ProductDetails";
import SubProductDetails from "./Components/Product/ProductDetails/SubProductDetails";
import TeamArea from "./Components/HomeOne/TeamArea";
import Iso from "./Components/ISO";
import { useTranslation } from "react-i18next";

const LanguageRoutes = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return (
    <Routes>
      <Route path="/" element={<HomeOne />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/team" element={<TeamArea />} />
      <Route path="/team-details/:teamId" element={<TeamDetails />} />
      <Route path="/error" element={<Error />} />
      <Route path="/products" element={<Product />} />
      <Route path="/products/:productLink" element={<ProductDetails />} />
      <Route
        path="/products/:productLink/:subProductLink"
        element={<SubProductDetails />}
      />
      <Route path="/services" element={<Services />} />
      <Route path="/services/:serviceLink" element={<ServiceDetails />} />
      <Route path="/iso" element={<Iso />} />
    </Routes>
  );
};

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/cs/" />} />
      <Route path="/:lang/*" element={<LanguageRoutes />} />
    </Routes>
  );
}

export default Routers;
