import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/logo/logo.png";

function HeaderOne({ className }) {
  const [drawer, setDrawer] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("menu");
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (drawer) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  }, [drawer]);

  const changeLanguage = (lng) => {
    const currentPath = location.pathname.split("/").slice(2).join("/");
    navigate(`/${lng}/${currentPath}`);
    i18n.changeLanguage(lng);
  };

  const isActive = (path) => {
    const currentPath = location.pathname.split("/").slice(2).join("/");
    return path === currentPath;
  };

  const menuItems = [
    { name: t("home"), path: "/", active: isActive("/") },
    { name: t("about"), path: "/about", active: isActive("/about") },
    { name: t("products"), path: "/products", active: isActive("/products") },
    { name: t("services"), path: "/services", active: isActive("/services") },
    { name: t("iso"), path: "/iso", active: isActive("/iso") },
    { name: t("contact"), path: "/contact", active: isActive("/contact") },
  ];

  const renderMenuItem = (item) => (
    <li key={item.name} className={item.active ? "active" : ""}>
      <a href={`/${currentLanguage}${item.path}`}>{item.name}</a>
      {item.children && (
        <ul className="sub-menu">
          {item.children.map((child) => (
            <li key={child.name} className={child.active ? "active" : ""}>
              <a href={`/${currentLanguage}${child.path}`}>{child.name}</a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );

  return (
    <header>
      <div
        id="sticky-header"
        className={`menu-area transparent-header ${className || ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                onClick={() => setDrawer(!drawer)}
                className="mobile-nav-toggler"
              >
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo different-logo">
                    <a href={`/${currentLanguage}/`}>
                      <img src={logo} alt="Logo" />
                    </a>
                  </div>

                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {menuItems.map(renderMenuItem)}
                    </ul>
                    <div className="language-switcher d-flex align-items-center ">
                      <button onClick={() => changeLanguage("cs")}>
                        <img
                          src={require("../../../assets/img/icon/flags/cs.png")}
                          alt="Čeština"
                          style={{ width: "30px", height: "30px" }}
                        />
                      </button>
                      /
                      <button onClick={() => changeLanguage("en")}>
                        <img
                          src={require("../../../assets/img/icon/flags/en.png")}
                          alt="English"
                          style={{ width: "30px", height: "20px" }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="header-btn">
                        <a href={`/${currentLanguage}/contact`} className="btn">
                          {t("contactUs")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div onClick={() => setDrawer(!drawer)} className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href={`/${currentLanguage}/`}>
                      <img src={logo} alt="Logo" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <ul className="navigation">
                      {menuItems.map(renderMenuItem)}
                    </ul>
                  </div>
                  <div className="language-switcher">
                    <button onClick={() => changeLanguage("cs")}>
                      <img
                        src={require("../../../assets/img/icon/flags/cs.png")}
                        alt="Čeština"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </button>
                    /
                    <button onClick={() => changeLanguage("en")}>
                      <img
                        src={require("../../../assets/img/icon/flags/en.png")}
                        alt="English"
                        style={{ width: "30px", height: "20px" }}
                      />
                    </button>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderOne;
