import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import csAbout from "./assets/data/translation/cs/about.json";
import enAbout from "./assets/data/translation/en/about.json";
import csMenu from "./assets/data/translation/cs/menu.json";
import enMenu from "./assets/data/translation/en/menu.json";
import csHero from "./assets/data/translation/cs/hero.json";
import enHero from "./assets/data/translation/en/hero.json";
import csProducts from "./assets/data/translation/cs/products.json";
import enProducts from "./assets/data/translation/en/products.json";
import csProductsData from "./assets/data/translation/cs/productsData.json";
import enProductsData from "./assets/data/translation/en/productsData.json";
import csServices from "./assets/data/translation/cs/servicesData.json";
import enServices from "./assets/data/translation/en/servicesData.json";
import csFooter from "./assets/data/translation/cs/footer.json";
import enFooter from "./assets/data/translation/en/footer.json";
import csServicesTrans from "./assets/data/translation/cs/services.json";
import enServicesTrans from "./assets/data/translation/en/services.json";
import csHistory from "./assets/data/translation/cs/history.json";
import enHistory from "./assets/data/translation/en/history.json";
import csTeam from "./assets/data/translation/cs/team.json";
import enTeam from "./assets/data/translation/en/team.json";
import csContact from "./assets/data/translation/cs/contact.json";
import enContact from "./assets/data/translation/en/contact.json";
import enISO from "./assets/data/translation/en/iso.json";
import csISO from "./assets/data/translation/cs/iso.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      cs: {
        about: csAbout,
        menu: csMenu,
        hero: csHero,
        products: csProducts,
        productsData: csProductsData,
        services: csServices,
        footer: csFooter,
        servicesTrans: csServicesTrans,
        history: csHistory,
        team: csTeam,
        contact: csContact,
        iso: csISO,
      },
      en: {
        about: enAbout,
        menu: enMenu,
        hero: enHero,
        products: enProducts,
        productsData: enProductsData,
        services: enServices,
        footer: enFooter,
        servicesTrans: enServicesTrans,
        history: enHistory,
        team: enTeam,
        contact: enContact,
        iso: enISO,
      },
    },
    fallbackLng: "cs",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    ns: [
      "about",
      "menu",
      "hero",
      "products",
      "productsData",
      "services",
      "footer",
      "servicesTrans",
      "history",
      "team",
      "contact",
      "iso",
    ],
    defaultNS: "about",
  });

export default i18n;
